<style>
.c-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: .6px;
  color: #bfc8e2 !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}
.c-server-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: .5px;
  color: #bfc8e2 !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}
.c-link:hover {
  color: #a7b0c9 !important;
}
@media (min-width: 1366px) {
  .c-links {
    margin-left: 5rem;
    margin-top: 2rem;
  }
}
@media (max-width: 1366px) {
  .c-links {
    margin-top: 2rem;
  }
}
.c-component-col {
  margin-top: 2rem;
}
.c-link.active {
  color: white !important;
}
.mobile-menu {
  width: 100vw;
  height: 3rem;
  background-color: #545a6d;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 1rem;
}
.mobile-container {
  height: 3rem;
  min-width: 4rem;

  text-align: center;

  padding-top: 0.75rem;
  padding-bottom: 2rem;

  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  white-space: nowrap;
}
.mobile-container-last {
  min-width: 1px !important;
}
.mobile-container.active {
  border-bottom: 3px solid var(--primary);
}
</style>
<script>
import { VclCode } from 'vue-content-loading';

import { HalfCircleSpinner, CirclesToRhombusesSpinner } from 'epic-spinners'
import {AccountStatus, Game} from '@/enums';
import CopyableText from "@/components/CopyableText";

export default {
  props: ['server_id', 'component'],
  components: {
    CopyableText,
    HalfCircleSpinner,
    /* Desktop */
    DOverview: () => import('@/components/lazy/servers/view/desktop/Overview'),
    DPlayers: () => import('@/components/lazy/servers/view/desktop/Players'),
    DGraphs: () => import('@/components/lazy/servers/view/desktop/Graphs'),
    //Logs
    DProtocol: () => import('@/components/lazy/servers/view/desktop/Protocol'),
    DLogsServer: () => import('@/components/lazy/servers/view/desktop/LogsServer'),
    DLogsRCon: () => import('@/components/lazy/servers/view/desktop/LogsRCon'),
    // Configuration
    DModules: () => import('@/components/lazy/servers/view/desktop/Configuration'),
    DTriggers: () => import('@/components/lazy/servers/view/desktop/Triggers'),
    DScheduler: () => import('@/components/lazy/servers/view/desktop/Scheduler'),
    DMessenger: () => import('@/components/lazy/servers/view/desktop/Messenger'),
    DIGCommands: () => import('@/components/lazy/servers/view/desktop/IGCommands'),
    DLocalization: () => import('@/components/lazy/servers/view/desktop/Localization'),
	  DGameIntegration: () => import('@/components/lazy/servers/view/desktop/GameIntegration'),
    //Management
    DSettings: () => import('@/components/lazy/servers/view/desktop/Settings'),
    DIntegrations: () => import('@/components/lazy/servers/view/desktop/IntegrationsNav'),
	  DRCon: () => import('@/components/lazy/servers/view/desktop/RCon.vue'),
	  DRadar: () => import('@/components/lazy/servers/view/desktop/Radar.vue'),
    // Generic bucket
    DWhitelist: () => import('@/components/lazy/servers/view/desktop/Whitelist'),
    DQueuePriority: () => import('@/components/lazy/servers/view/desktop/QueuePriority'),
    DReservedSlots: () => import('@/components/lazy/servers/view/desktop/ReservedSlots'),
    // Extended bucket
    DFilterChat: () => import('@/components/lazy/servers/view/desktop/FilterChat'),
    DFilterName: () => import('@/components/lazy/servers/view/desktop/FilterName'),
    DUsers: () => import('@/components/lazy/servers/view/desktop/Users'),
    /* Mobile */
    MOverview: () => import('@/components/lazy/servers/view/desktop/Overview'),
    MSettings: () => import('@/components/lazy/servers/view/desktop/Settings'),
    MIntegrations: () => import('@/components/lazy/servers/view/components/integrations/Webhooks.vue'),
  },
  methods: {
    routeToComponent(component) {
      switch(component) {
        case 'overview': return 'DOverview';
        case 'playerdb': return 'DPlayers';
        case 'graphs': return 'DGraphs';
        case 'logs-server': return 'DLogsServer';
        case 'protocol': return 'DProtocol';
        case 'logs-rcon': return 'DLogsRCon';
        case 'modules': return 'DModules';
        case 'triggers': return 'DTriggers';
        case 'scheduler': return 'DScheduler';
        case 'messenger': return 'DMessenger';
        case 'igcommands': return 'DIGCommands';
        case 'localization': return 'DLocalization';
        case 'settings': return 'DSettings';
        case 'integrations': return 'DIntegrations';
        case 'whitelist': return 'DWhitelist';
        case 'queuepriority': return 'DQueuePriority';
        case 'reservedslots': return 'DReservedSlots';
        case 'filter-chat': return 'DFilterChat';
        case 'filter-name': return 'DFilterName';
        case 'users': return 'DUsers';
        case 'm-overview': return 'MOverview';
        case 'm-settings': return 'MSettings';
        case 'm-integrations': return 'MIntegrations';
	      case 'game-integration': return 'DGameIntegration';
        case 'rcon': return 'DRCon';
	      case 'radar': return 'DRadar';
        default: return this.is_mobile ? 'MOverview' : 'DOverview';
      }
    },
    componentToRoute(component) {
      switch(component) {
          case 'DOverview': return 'overview';
          case 'DPlayers': return 'playerdb';
          case 'DGraphs': return 'graphs';
          case 'DLogsServer': return 'logs-server';
          case 'DProtocol': return 'protocol';
          case 'DLogsRCon': return 'logs-rcon';
          case 'DModules': return 'modules';
          case 'DTriggers': return 'triggers';
          case 'DScheduler': return 'scheduler';
          case 'DMessenger': return 'messenger';
          case 'DIGCommands': return 'igcommands';
          case 'DLocalization': return 'localization';
          case 'DSettings': return 'settings';
          case 'DIntegrations': return 'integrations';
          case 'DWhitelist': return 'whitelist';
          case 'DQueuePriority': return 'queuepriority';
          case 'DReservedSlots': return 'reservedslots';
          case 'DFilterChat': return 'filter-chat';
          case 'DFilterName': return 'filter-name';
          case 'DUsers': return 'users';
          case 'MOverview': return 'm-overview';
          case 'MSettings': return 'm-settings';
          case 'MIntegrations': return 'm-integrations';
	        case 'DGameIntegration': return 'game-integration';
          case 'DRCon': return 'rcon';
	        case 'DRadar': return 'radar';
          default: return '';
      }
    },
    changeTab(tab, options) {
      this.options = options || {};
      this.currentTab = tab;
      this.options.privileged = this.isPrivileged;
      this.options.permissions = this.permissions;
      this.options.game = this.game;
      let component = this.componentToRoute(tab);
      if(component === '') {
        history.pushState({}, null, `/server/${this.server_id}`);
      } else {
        history.pushState({}, null, `/server/${this.server_id}/${component}`);
      }
    },
    tabChangeRequest(tab) {
      this.currentTab = tab;
    },
    changeMobileTab(tab, options) {
      this.options = options || {};
      this.currentTab = tab;
      this.options.privileged = this.isPrivileged;
      this.options.permissions = this.permissions;
      this.options.game = this.game;
      let component = this.componentToRoute(tab);
      if(component === '') {
        history.pushState({}, null, `/server/${this.server_id}`);
      } else {
        history.pushState({}, null, `/server/${this.server_id}/${component}`);
      }
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/structure`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            if(response.status === 403) this.isUnauthorized = true;
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.game = data.server.game;
          this.permissions = data.permissions;
          this.isPrivileged = (data.permissions.administrator === true);
          this.options.privileged = (data.permissions.administrator === true);
          this.options.permissions = this.permissions;
          this.options.game = this.game;
          this.features = data.features;
          this.$emit('identifierLoaded', data.server.identifier, data.permissions.configuration);
          /*
          Ready event invokes rendering of the sub-components which depend on the above data to be available.
          Sometimes, the proxy setter is too slow and we end up with a race condition, resulting in a failed page load.
          Adding a slow delay fixes this.
          */
          setTimeout(() => {
            this.ready = true;
          }, 1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    actionToggleFullpage() {
      this.toggleOverlay();
    },
    actionEnableFullpage(text, color, spinner) {
      this.overlayText = text;
      this.overlayTextColor = color;
      this.overlaySpinner = spinner;
    },
    toggleOverlay: function() {
      this.overlayEnabled = !this.overlayEnabled;
    },
    supportsGraphs() {
      let games = [Game.DAYZ];
      return games.includes(this.game);
    },
    supportsQP() {
      let games = [Game.DAYZ];
      return games.includes(this.game);
    },
    supportsRS() {
      let games = [];
      return games.includes(this.game);
    },
    supportsWL() {
      let games = [Game.DAYZ, Game.ARMA3, Game.ARMA2];
      return games.includes(this.game);
    }
  },
  created() {
    this.currentTab = this.routeToComponent(this.component);
  },
  mounted() {
    this.getData();
    try {
      // Should this fail for whatever reason, fall back
      let element = document.getElementsByClassName('c-mobile')[0];
      if (window.getComputedStyle(element, null).display === 'block') {
        this.is_mobile = true;
        this.changeTab(this.routeToComponent(this.component));
      }
      else this.is_mobile = false;
    } catch(e) {
      this.is_mobile = null;
    }
  },
  data() {
    return {
      is_mobile: null,
      currentTab: 'DOverview',
      options: {
        game: null,
        permissions: {
          settings: false,
          configuration: false,
          whitelist: false,
          queuepriority: false,
          reservedslots: false,
          log: {
            audit: false,
            server: false,
            rcon: false
          }
        }
      },
      features: {},
      ready: false,
      error: false,
      isUnauthorized: false,
      isPrivileged: false,
      permissions: {},
      overlayEnabled: false,
      overlayText: '',
      overlayTextColor: '',
      overlaySpinner: false
    }
  }
};
</script>

<template>
  <div :class="{'fullpage-overlay-enabled': overlayEnabled}">
    <div class="fullpage-overlay">
      <div class="fullpage-overlay-content">
        <h3 :class="'text-'+overlayTextColor">
          <half-circle-spinner
              v-if="overlaySpinner"
              :animation-duration="1200"
              :size="24"
              class="align-middle d-inline-block mr-2"
          />
          {{ overlayText }}
        </h3>
      </div>
    </div>
    <template v-if="error">
      <div class="row mt-4">
        <div class="col justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12" v-if="isUnauthorized">
                <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.permissions.title')}}</h3>
                <h5 class="text-muted">{{$t('error.permissions.message')}}</h5>
                <h6 class="text-muted">{{$t('error.permissions.details')}}</h6>
              </div>
              <div class="col-lg-12" v-else>
                <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.server.generic.title')}}</h3>
                <h5 class="text-muted">{{$t('error.server.generic.component')}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Desktop -->
      <div class="c-desktop">
        <div class="container-fluid" v-if="is_mobile === false && ready">
          <div class="row">
            <div class="col-2">
              <div class="c-links">
                <div class="c-link text-muted" :class="{'active': currentTab === 'DOverview'}" v-on:click="changeTab('DOverview')">
                  Overview
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DGraphs'}" v-on:click="changeTab('DGraphs')" v-if="supportsGraphs()">
                  Graphs
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DPlayers'}" v-on:click="changeTab('DPlayers')">
                  Player DB
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DProtocol'}" v-on:click="changeTab('DProtocol')" v-if="options.permissions.log.audit">
                  Audit Logs
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DLogsServer'}" v-on:click="changeTab('DLogsServer')" v-if="options.permissions.log.server">
                  Server Logs
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DLogsRCon'}" v-on:click="changeTab('DLogsRCon')" v-if="options.permissions.log.rcon">
                  RCon protocol
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DUsers'}" v-on:click="changeTab('DUsers')">
                  Personnel
                </div>
                <hr>
                <template v-if="supportsWL() && options.permissions.whitelist">
                  <div class="c-link text-muted" :class="{'active': currentTab === 'DWhitelist'}" v-on:click="changeTab('DWhitelist')">
                    Whitelist
                  </div>
                </template>
                <template v-if="supportsRS() && options.permissions.reservedslots">
                  <div class="c-link text-muted" :class="{'active': currentTab === 'DReservedSlots'}" v-on:click="changeTab('DReservedSlots')">
                    Reserved Slots
                  </div>
                </template>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DFilterChat'}" v-on:click="changeTab('DFilterChat')" v-if="options.permissions.configuration">
                  Chat Filter
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DFilterName'}" v-on:click="changeTab('DFilterName')" v-if="options.permissions.configuration">
                  Name Filter
                </div>
                <template v-if="supportsQP() && options.permissions.queuepriority">
                  <div class="c-link text-muted" :class="{'active': currentTab === 'DQueuePriority'}" v-on:click="changeTab('DQueuePriority')">
                    Queue Priority
                  </div>
                </template>
                <template>
                  <hr>
                  <div v-if="options.permissions.configuration" class="c-link text-muted" :class="{'active': currentTab === 'DModules'}" v-on:click="changeTab('DModules')">
                    Modules
                  </div>
                  <div v-if="options.permissions.configuration" class="c-link text-muted" :class="{'active': currentTab === 'DTriggers'}" v-on:click="changeTab('DTriggers')">
                    Triggers
                  </div>
                  <div v-if="options.permissions.configuration" class="c-link text-muted" :class="{'active': currentTab === 'DScheduler'}" v-on:click="changeTab('DScheduler')">
                    Scheduler
                  </div>
                  <div v-if="options.permissions.configuration" class="c-link text-muted" :class="{'active': currentTab === 'DMessenger'}" v-on:click="changeTab('DMessenger')">
                    Messenger
                  </div>
                  <div v-if="options.permissions.configuration" class="c-link text-muted" :class="{'active': currentTab === 'DIGCommands'}" v-on:click="changeTab('DIGCommands')">
                    In-Game Commands
                  </div>
                </template>
	              <template v-if="features.radar === true">
		              <hr/>
		              <div class="c-link text-muted" :class="{'active': currentTab === 'DRadar'}" v-on:click="changeTab('DRadar')">
			              <i class="fad fa-radar" /> Radar
		              </div>
	              </template>
                <hr>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DRCon'}" v-on:click="changeTab('DRCon')">
                  RCon
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DSettings'}" v-on:click="changeTab('DSettings')" v-if="options.permissions.settings">
                  Settings
                </div>
                <div v-if="options.permissions.configuration" class="c-link text-muted" :class="{'active': currentTab === 'DLocalization'}" v-on:click="changeTab('DLocalization')">
                  Localization
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DIntegrations'}" v-on:click="changeTab('DIntegrations')" v-if="options.permissions.integrations">
                  Integrations
                </div>
                <div class="c-link text-muted" :class="{'active': currentTab === 'DGameIntegration'}" v-on:click="changeTab('DGameIntegration')" v-if="options.permissions.configuration">
                  Game Integration
                </div>
              </div>
            </div>
            <div class="col c-component-col">
              <component v-if="ready" @actionEnableFullpage="actionEnableFullpage" @actionToggleFullpage="actionToggleFullpage" @tabChangeRequest="tabChangeRequest" :options="options" :server_id="server_id" v-bind:is="currentTab"></component>
            </div>
          </div>
        </div>
      </div>
      <!-- Desktop END -->

      <!-- MOBILE -->
      <div class="c-mobile">
        <div v-if="is_mobile === true && ready">
          <div class="mobile-menu">
            <div class="mobile-container" :class="{'active': currentTab === 'MOverview'}" v-on:click="changeMobileTab('MOverview')">
              Overview
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DPlayers'}" v-on:click="changeTab('DPlayers')">
              Player DB
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DProtocol'}" v-on:click="changeTab('DProtocol')" v-if="options.permissions.log.audit">
              Audit Logs
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DLogsServer'}" v-on:click="changeTab('DLogsServer')" v-if="options.permissions.log.server">
              Server Logs
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DLogsRCon'}" v-on:click="changeTab('DLogsRCon')" v-if="options.permissions.log.rcon">
              RCon protocol
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DUsers'}" v-on:click="changeTab('DUsers')">
              Personnel
            </div>
            <template v-if="options.permissions.whitelist && supportsWL()">
              <div class="mobile-container" :class="{'active': currentTab === 'DWhitelist'}" v-on:click="changeTab('DWhitelist')">
                Whitelist
              </div>
            </template>
            <template v-if="options.permissions.reservedslots && supportsRS()">
              <div class="mobile-container" :class="{'active': currentTab === 'DReservedSlots'}" v-on:click="changeTab('DReservedSlots')">
                Reserved Slots
              </div>
            </template>
            <div class="mobile-container" :class="{'active': currentTab === 'DFilterChat'}" v-on:click="changeTab('DFilterChat')" v-if="options.permissions.configuration">
              Chat Filter
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DFilterName'}" v-on:click="changeTab('DFilterName')" v-if="options.permissions.configuration">
              Name Filter
            </div>
            <template v-if="options.permissions.queuepriority && supportsQP()">
              <div class="mobile-container" :class="{'active': currentTab === 'DQueuePriority'}" v-on:click="changeTab('DQueuePriority')">
                Queue Priority
              </div>
            </template>

            <template>
              <div v-if="options.permissions.configuration" class="mobile-container" :class="{'active': currentTab === 'DModules'}" v-on:click="changeTab('DModules')">
                Modules
              </div>
              <div v-if="options.permissions.configuration" class="mobile-container" :class="{'active': currentTab === 'DTriggers'}" v-on:click="changeTab('DTriggers')">
                Triggers
              </div>
              <div v-if="options.permissions.configuration" class="mobile-container" :class="{'active': currentTab === 'DScheduler'}" v-on:click="changeTab('DScheduler')">
                Scheduler
              </div>
              <div v-if="options.permissions.configuration" class="mobile-container" :class="{'active': currentTab === 'DMessenger'}" v-on:click="changeTab('DMessenger')">
                Messenger
              </div>
              <div v-if="options.permissions.configuration" class="mobile-container" :class="{'active': currentTab === 'DIGCommands'}" v-on:click="changeTab('DIGCommands')">
                IG Commands
              </div>
            </template>
	          <template v-if="features.radar === true">
		          <hr/>
		          <div class="mobile-container" :class="{'active': currentTab === 'DRadar'}" v-on:click="changeTab('DRadar')">
			          <i class="fad fa-radar" /> Radar
		          </div>
	          </template>
            <div class="mobile-container" :class="{'active': currentTab === 'DRCon'}" v-on:click="changeTab('DRCon')">
              RCon
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DSettings'}" v-on:click="changeTab('DSettings')" v-if="options.permissions.settings">
              Settings
            </div>
            <div v-if="options.permissions.configuration" class="mobile-container" :class="{'active': currentTab === 'DLocalization'}" v-on:click="changeTab('DLocalization')">
              Localization
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DIntegrations'}" v-on:click="changeTab('DIntegrations')" v-if="options.permissions.integrations">
              Integrations
            </div>
            <div class="mobile-container" :class="{'active': currentTab === 'DGameIntegration'}" v-on:click="changeTab('DGameIntegration')" v-if="options.permissions.configuration">
              Game Integration
            </div>
            <div class="mobile-container mobile-container-last"/>
          </div>
          <div class="mobile-content">
            <component v-if="ready" @actionEnableFullpage="actionEnableFullpage" @actionToggleFullpage="actionToggleFullpage" @tabChangeRequest="tabChangeRequest" :options="options" :server_id="server_id" v-bind:is="currentTab"></component>
          </div>
        </div>
      </div>

      <div v-if="is_mobile === null">
        <div class="row" style="margin-top: 70px;">
          <div class="col-lg-12">
            <div class="card bg-danger">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-1 text-white">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.viewport.title') }}
                </h5>
                <p class="card-text text-white">
                  {{ $t('profile.errors.viewport.message') }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12 justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
